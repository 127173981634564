import { useTranslation } from 'react-i18next';
import { ReactComponent as HandIcon } from '../assets/icons/lab-1.svg';
import { ReactComponent as PhoneIcon } from '../assets/icons/phone.svg';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';

import './info-contact-card.scss';

const infoContactBg = require('../assets/images/info-contact-card-bg.jpg');

const InfoContactCard = () => {

    const { t } = useTranslation();

    return (
        <div className="info-contact-card-container">
            <img src={infoContactBg} alt="" />
            <div className='content'>
                <HandIcon />
                <div className='title'>{t('side-menu.contact-card.title')}</div>
                <div className='phone'>
                    <PhoneIcon width={20} height={20}/>
                    +(123) 1234-567-8901
                </div>
                <div className='contact-button'>
                    Contact
                    <ArrowIcon/>
                </div>
            </div>
        </div>
    )
}

export default InfoContactCard;