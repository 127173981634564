import { useLocation } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';
import './header-mobile-info-section.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const HeaderMobileInfoSection = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [backgroundImage, setBackgroundImage] = useState();


    const pathSegments = location.pathname.split('/').filter(Boolean);

    let translationKey = 'home';  

    if (pathSegments[0] === 'research' && pathSegments[1]?.startsWith('national-projects')) {
        const projectGroup = pathSegments[1].split('-')[2];  
        const projectId = pathSegments[2] || '1';            

        translationKey = `national-projects-${projectGroup}.${projectId}`;
    } else if (pathSegments[0] === 'events' && pathSegments[1]?.startsWith('workshop')) {
        const projectGroup = pathSegments[1].split('-')[2];  
        const projectId = pathSegments[2] || '1';     
               

        translationKey = `workshop-${projectId}`;
    } else {
        translationKey = pathSegments[pathSegments.length - 1] || 'home';
    }

    const isHome = translationKey === 'home';


    
    useEffect(() => {
        try {
        const path = location.pathname;
        const pathSegments = path.split('/').filter(Boolean); 
        
        if (pathSegments.length >= 3 && pathSegments[1].startsWith('national-projects')) {
            const projectSegment = pathSegments[1]; 
            const lastSegment = pathSegments[pathSegments.length - 1];  

                const imagePath = require(`../assets/images/${projectSegment}/${lastSegment}-bg.png`);
                setBackgroundImage(imagePath);
        } else if (pathSegments.length >= 3 && pathSegments[1] === 'workshop') {
            const workshopKey = `workshop-${pathSegments[2]}`; 
            const lastSegment = pathSegments[pathSegments.length - 1]; 
            const resolvedImagePath = require(`../assets/images/${workshopKey}/${lastSegment}-bg.png`);
            setBackgroundImage(resolvedImagePath);

        } else {
            const lastSegment = path.substring(path.lastIndexOf('/') + 1);
                const originalImagePath = require(`../assets/images/${lastSegment}-bg.png`);
                setBackgroundImage(originalImagePath);
        }
        } catch (e) {
            console.log(e);
            
        }
    }, [location.pathname]);

    return (
        <div className='mobile-info-section-container'>
                <div className='image-container' style={{ background: `url(${backgroundImage})` }}>
                </div>
            <div className="section">
                <div className='title-help-text'>{t(`header.${translationKey}.badge-text`)}</div>
                <div className='title'>
                    {t(`header.${translationKey}.title`)}
                </div>
                <div className='separator'></div>
                <div className='subtitle'> {t(`header.${translationKey}.description`)}.</div>
                {isHome &&
                    <div className='details-container'>
                        <span>Detalii</span>
                        <ArrowIcon />
                    </div>
                }
            </div>
        </div>
    )
}

export default HeaderMobileInfoSection;