

import { ReactComponent as Lab1 } from '../../assets/icons/lab-1.svg';
import { ReactComponent as Lab2 } from '../../assets/icons/lab-2.svg';
import { ReactComponent as Lab3 } from '../../assets/icons/lab-3.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import './home-page.section.laboratories.scss';

const HomePageSectionLaboratories = () => {

    return (
        <div className="homepage-laboratories-info-container">
            <div className="laboratory-container">
                <div className='arrow-container'>
                    <ArrowIcon />
                </div>
                <div className='title-section'>

                    <Lab1 />
                    <span>
                        Laborator de ameliorare, specii pomicole, arbusti fructiferi si capsuni

                    </span>
                </div>
                <div className='separator'></div>
                <div className='descripton'>
                    Laboratorul desfășoară activități de cercetare de ameliorare în scopul obținerii de produse pomicole cu caracteristici solicitate de piață.
                </div>
            </div>
            {/* <div className="laboratory-container">
                <div className='arrow-container'>
                    <ArrowIcon />
                </div>
                <div className='title-section'>

                    <Lab2 />
                    <span>
                        Laborator de agrotehnica si protectia plantelor
                    </span>
                </div>
                <div className='separator'></div>
                <div className='descripton'>
                    Laboratorul desfășoară cercetări privind tehnicile și tehnologiile aplicabile în cultivarea soiurilor noi din speciile de cais, piersic şi nectarin,
                </div>
            </div> */}
            <div className="laboratory-container">
                <div className='arrow-container'>
                    <ArrowIcon />
                </div>
                <div className='title-section'>

                    <Lab3 />
                    <span>
                        Laborator de cercetare/Inovare in pomicultura ecologica

                    </span>
                </div>
                <div className='separator'></div>
                <div className='descripton'>
                    Laboratorul este destinat cercetării dedicate tehnicilor de producție pomicolă în sistem ecologic certificat,
                </div>
            </div>
        </div>)
}

export default HomePageSectionLaboratories