import { useTranslation } from "react-i18next";
import SideMenu from "../../../components/side-menu";
import { ReactNode, useMemo, useState } from "react";
import Laboratory3PagePartAchievements from "./laboratory-3-page.part.achievements";
import Laboratory3PagePartDescription from "./laboratory-3-page.part.description";
import Laboratory3PagePartGallery from "./laboratory-3-page.part.gallery";
import Laboratory3PagePartPersonal from "./laboratory-3-page.part.personal";
import Laboratory3PagePartProjects from "./laboratory-3-page.part.projects";
import './laboratory-3-page.scss'


interface Topic {
    label: string,
    value: ReactNode
}

const Laboratory2Page: React.FC = () => {

    const { t } = useTranslation();
    const subMenuItems = useMemo(() => [
        { label: t('laboratory.submenu.item-1'), value: <Laboratory3PagePartDescription/> },
        { label: t('laboratory.submenu.item-2'), value: <Laboratory3PagePartPersonal/> },
        { label: t('laboratory.submenu.item-3'), value: <Laboratory3PagePartProjects/> },
        { label: t('laboratory.submenu.item-4'), value: <Laboratory3PagePartGallery/> },
        { label: t('laboratory.submenu.item-5'), value: <Laboratory3PagePartAchievements/> }
    ], [t]);
    const [activeTopic, setActiveTopic] = useState<Topic>(subMenuItems[0]);

    const handleChangeTopic = (topic: Topic) => {
        setActiveTopic(topic)
    }


    return (
        <div className="laboratory-1-page page">
            <SideMenu />
            <div className="page-content">
                <div className="topic-container">
                    <div className="title">{t('laboratory-3.page.title')}</div>

                    <div className="submenu-container">
                        {
                            subMenuItems.map((item, index) => (
                                <button
                                    className={`submenu-item ${activeTopic.value === item.value ? 'active ' : ''}`}
                                    onClick={() => handleChangeTopic(item)}
                                    key={index}>
                                    {item.label}
                                </button>
                            ))
                        }
                    </div>
                    {activeTopic.value}
                </div>
            </div>
        </div>
    )
}

export default Laboratory2Page;