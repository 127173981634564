import { RouteObject } from "react-router-dom";
import EventsPage from "./events-page";
import Event1Page from "./event-1/event-1-page";
import WorkshopLayout from "./event-1/workshop.layout";

export const eventsRouter: RouteObject[] = [
    {
        path: '',
        element: <EventsPage/>
    }, 
    {
        path: 'event-1',
        element: <Event1Page/>
    },
    {
        path: '/events/workshop/:id',
        element: <WorkshopLayout/>
    }
] 