import { useLocation } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';
import './header-info-section.scss';
import { useTranslation } from 'react-i18next';

const HeaderInfoSection = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const pathSegments = location.pathname.split('/').filter(Boolean);

    let translationKey = 'home';  

    if (pathSegments[0] === 'research' && pathSegments[1]?.startsWith('national-projects')) {
        const projectGroup = pathSegments[1].split('-')[2];  
        const projectId = pathSegments[2] || '1';            

        translationKey = `national-projects-${projectGroup}.${projectId}`;
    } else if (pathSegments[0] === 'events' && pathSegments[1]?.startsWith('workshop')) {
        const projectGroup = pathSegments[1].split('-')[2];  
        const projectId = pathSegments[2] || '1';     
               

        translationKey = `workshop-${projectId}`;
    } else {
        translationKey = pathSegments[pathSegments.length - 1] || 'home';
    }

    const isHome = translationKey === 'home';

    return (
        <div className='info-section-container'>
            <div className="section">
                <div className='title-help-text'>{t(`header.${translationKey}.badge-text`)}</div>
                <div className='title'>{t(`header.${translationKey}.title`)}</div>
                <div className='separator'></div>
                <div className='subtitle'>{t(`header.${translationKey}.description`)}</div>

                {isHome && (
                    <div className='details-container'>
                        <span>Detalii</span>
                        <ArrowIcon />
                    </div>
                )}
            </div>
        </div>
    );
};

export default HeaderInfoSection;
