import SideMenu from "../../components/side-menu";
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import './events-page.scss';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const eventImage1 = require('../../assets/images/event-1.png');

interface Item {
    image: string;
    category: string;
    title: string;
}

const EventsPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const items: Item[] = [
        {
            image: eventImage1,
            category: 'FOOD CROPS',
            title: 'Cercetare pomicola- Tendinte si tehnologii moderne',
        }
    ]
    const handleNavigate = (path: any) => {
        navigate(`event-${path}`)
    }

    const eventsItems = items.map((item, index) => (
        <div className='item' key={index} onClick={() => handleNavigate(index+1)}>
            <div className='image-container'>
                <img src={item.image} alt={item.category} />
                <div className='category'><span>{item.category}</span></div>
                <div className='arrow-container'>
                    <ArrowIcon />
                </div>
            </div>
            <div className='title'>{item.title}</div>

        </div>
    ));
    return (
        <div className="events-page page">
            <div className="page-content">
                <div className="events-container">
                    {eventsItems.map((item) => (item))}
                </div>
            </div>
        </div>
    )
}

export default EventsPage;