import React from 'react';
import './number-indicator.scss';

interface NumberIndicatorProps {
    number: number;
    outline?: boolean; 
}

const NumberIndicator: React.FC<NumberIndicatorProps> = ({ number, outline }) => {

    return (
        <div className={`number-indicator ${outline ? 'outline' : ''}`}>
            {number}
        </div>
    );
};

export default NumberIndicator;
