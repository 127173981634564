import { ReactComponent as LeafIcon } from '../../../assets/icons/leaf.svg';
import { ReactComponent as PdfIcon } from '../assets/icons/pdf.svg';
import { ReactComponent as DownloadIcon } from '../assets/icons/download.svg';
import { useTranslation } from 'react-i18next';
import DownloadButton from '../../../components/download-button';
import InfoContactCard from '../../../components/info-contact-card';
import InfoRequestPublicInterest from '../../../components/info-request-public-interest';
import './national-projects.side-menu.scss';
import SideMenuSubProjects from './national-projects.side-menu.subprojects';


const SideMenuProjects = () => {
    const { t } = useTranslation();
    return (
        <div className='side-menu-container-projects'>
            <SideMenuSubProjects />
            <InfoContactCard />
            <div className='about-baneasa-container'>
                <div className='item title'>
                    <LeafIcon width={20} height={20} />
                    <span>{t('side-menu.about-baneasa.title')}</span>
                </div>
                <DownloadButton theme='white' label={t('side-menu.button.about-us')}/>
                <DownloadButton theme='yellow' label={t('side-menu.button.catalog')}/>
            </div>
        </div>
    )
}
export default SideMenuProjects;