import { useTranslation } from "react-i18next";
import SideMenu from "../../../components/side-menu";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CheckIcon } from '../../../assets/icons/verified-yellow.svg';
import NumberIndicator from "../../../components/number-indicator";
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-light-green.svg';
import './event-1-page.scss';


const eventMainImage = require('../../../assets/images/event-1/event-1-main.png');

const Event1Page = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = (index: number) => {
        navigate(`/events/workshop/${index}`)
    }

    return (
        <div className="event-1-page page">
            <SideMenu showPublicInterest={false} />
            <div className="page-content">
                <img src={eventMainImage} />
                <div className="topic-container">
                    <div className="title">{t('events.page.event-1.title')}</div>

                    <div className="subtitle-content">
                        <div className="subtitle ">{t('events.page.event-1.program.list.title')}</div>
                        <ul>
                            {Array.from({length: 7}).map((_,index) => (
                                <li><CheckIcon/><p>{t(`events.page.event-1.program.list.item-${index+1}`)}</p></li>
                            ))}
                        </ul>
                    </div>

                    <div className="workshops-container">
                        {
                            Array.from({length: 4}).map((_,index) => (
                                <div className="workshop" onClick={() => handleNavigate(index+1)}>
                                    <NumberIndicator outline number={index+1} />
                                    <div className="separator"></div>

                                    <p className="title">{t('events.page.workshop')} {index+1}. <span>{t(`events.page.event-1.workshop-${index+1}.title`)}</span></p>

                                    <p className="description">{t(`events.page.event-1.workshop-${index+1}.description`)}</p>

                                    <div className="link">{t('events.page.details')} <ArrowIcon/></div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Event1Page;