import { Outlet } from "react-router-dom";
import './app-layout.scss';
import AppHeader from "./app-header";
import AppFooter from "./app-footer";
import { useState, useEffect } from "react";
import AppMobileHeader from "./app-mobile-header";

const AppLayout: React.FC = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);

    
    return (
        <div className="app-layout">
            {isMobile ? <AppMobileHeader/> : <AppHeader/>}
            <Outlet/>
            <AppFooter/>
        </div>
    )
} 
export default AppLayout; 