import { useTranslation } from "react-i18next";
import { useMemo, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SideMenuProjects from "./national-projects.side-menu";
import './national-projects-2.scss';

interface Topic {
    label: string;
    key: string;
}

const NationalProjects2Page: React.FC = () => {
    const { t } = useTranslation();
    const { projectId = '1' } = useParams();  
    const navigate = useNavigate();

    const subMenuItems: Topic[] = useMemo(() => {
        const items: Topic[] = [];
        
        for (let i = 1; i <= 6; i++) {
            const label = t(`plan-2.projects.${projectId}.submenu.${i}.label`);
            
            if (label && label !== `plan-2.projects.${projectId}.submenu.${i}.label`) {
                items.push({ label, key: `${i}` });
            }
        }
        return items;
    }, [projectId, t]);

    const [activeTopic, setActiveTopic] = useState<Topic>(subMenuItems[0]);

    useEffect(() => {
        if (!activeTopic && subMenuItems.length > 0) {
            setActiveTopic(subMenuItems[0]);
        }
    }, [subMenuItems, activeTopic]);

    const handleChangeTopic = (topic: Topic) => {
        setActiveTopic(topic);
    };

    const renderContent = (content: unknown) => {
        if (Array.isArray(content)) {
            return (
                <ul>
                    {content.map((item, index) => (
                        <li key={index}><p>{String(item)}</p></li> 
                    ))}
                </ul>
            );
        } else if (typeof content === "string") {
            return <p className="normal-text">{content}</p>;
        } else {
            return <p className="normal-text">No content available.</p>;
        }
    };

    const content = t(`plan-2.projects.${projectId}.submenu.${activeTopic.key}.content`, { returnObjects: true }) as string | string[];



    return (
        <div className="national-projects-1-page page">
            <SideMenuProjects />
            <div className="page-content">
                <div className="topic-container">
                    <div className="title">{t(`plan-2.projects.${projectId}.title`)}</div>
                    <div className="subtitle-content">
                    <p className="subtitle">{t(`plan-2.projects.${projectId}.description`)}</p>
                    </div>
                    <div className="submenu-container">
                        {subMenuItems.map((item, index) => (
                            <button
                                key={index}
                                className={`submenu-item ${activeTopic.key === item.key ? 'active' : ''}`}
                                onClick={() => handleChangeTopic(item)}
                            >
                                {item.label}
                            </button>
                        ))}
                    </div>

                    <div className="subtitle-content">
                        {renderContent(content)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NationalProjects2Page;
