import { useTranslation } from 'react-i18next';
import { ReactComponent as Verified } from '../../assets/icons/verified-yellow.svg';
import './research-page.scss';
import DownloadButton from '../../components/download-button';
import ResearchPagePartLaboratories from './research-page.part.laboratory';
import DownloadItem from '../../components/download-item';
import ResearchPagePartProjects from './research-page.part.projects';

const assortmentImage = require('../../assets/images/assortment-peaches-and-nectarines.png');

const apricot = [
    { label: 'Olimp' },
    { label: 'Excelsior' },
    { label: 'Dacia' },
    { label: 'Comandor' },
    { label: 'Favorit' },
    { label: 'Rares' },
    { label: 'Carmela' },
    { label: 'Viorica' },
    { label: 'Valeria' },
    { label: 'Nicusor' },
    { label: 'Adina' }
];

const peach = [
    { label: 'Flacara' },
    { label: 'Splendid' },
    { label: 'Superba de toamna' },
    { label: 'Congres' },
    { label: 'Triumf' },
    { label: 'Victoria' },
    { label: 'Antonia' },
    { label: 'Amalia' },
    { label: 'Alexia' },
    { label: 'Eugen' },
    { label: 'Dida' }
];


const nectarine = [
    { label: 'Tina' },
    { label: 'Mihaela' }
]

const catalogISTS = {
    label: 'Descarca Catalog'
}



const ResearchPage = () => {
    const { t } = useTranslation();

    const researchItems = [
        t('research.page.topic-1.section-1.item-1'),
        t('research.page.topic-1.section-1.item-2'),
        t('research.page.topic-1.section-1.item-3'),
    ]

    const infrastructureItems = [
        {
            text: t('research.page.topic-1.section-2.list.item-1')
        },
        {
            text: t('research.page.topic-1.section-2.list.item-2'),
            image: require('../../assets/images/eeris.png')
        },
        {
            text: t('research.page.topic-1.section-2.list.item-3'),
            image: require('../../assets/images/up2europe.png')
        },
        {
            text: t('research.page.topic-1.section-2.list.item-4')
        },
    ]

    return (
        <div className="research-page page">
            <div className="page-content">
                <div className='research-topic-container'>
                    <div className='subtopic-container'>
                        <div className='title'>{t('research.page.topic-1.title')}</div>
                        <div className='description'>{t('research.page.topic-1.section-1.description')}</div>
                        <div className='research-section'>
                            {
                                researchItems.map((item) => (
                                    <div className='research-item'>
                                        <Verified /> <span>{item}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='subtopic-container'>
                        <div className='title hidden'>{t('research.page.topic-1.title')}</div>
                        <div className='description bolded'>{t('research.page.topic-1.section-2.description')}</div>
                        <DownloadButton theme='yellow' label={t('research.page.topic-1.section-2.download-button')} />
                        <div className='description bolded'>{t('research.page.topic-1.section-2.list-description')}</div>
                        <div className='infrastructure-section'>
                            {infrastructureItems.map((item) => (
                                <div className='infrastructure-item'>
                                    <div className='dot'></div>
                                    <span>{item.text}</span>
                                    <img src={item.image}></img>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <ResearchPagePartLaboratories />
                <ResearchPagePartProjects />
                <div className='reports-and-results-container'>
                    <div className='title'>{t('research.page.topic-3.title')}</div>
                    <div className='reports-and-results'>
                        <div className='subtopic'>
                            <div className='subtitle'>{t('research.page.topic-3.section-1.title')}</div>
                            <img src={assortmentImage}></img>
                        </div>
                        <div className='subtopic'>
                            <div className='subtitle'>{t('research.page.topic-3.section-2.title')}</div>
                            <div className='category'>
                                <div className='title'>{t('research.page.topic-3.section-2.category-1.title')}</div>
                                <div className='results'>
                                    {apricot.map((item) => (
                                        <DownloadItem text={item.label} />
                                    ))}
                                </div>
                            </div>
                            <div className='category'>
                                <div className='title'>{t('research.page.topic-3.section-2.category-2.title')}</div>
                                <div className='results'>
                                    {peach.map((item) => (
                                        <DownloadItem text={item.label} />
                                    ))}
                                </div>
                            </div>
                            <div className='category'>
                                <div className='title'>{t('research.page.topic-3.section-2.category-3.title')}</div>
                                <div className='results'>
                                    {nectarine.map((item) => (
                                        <DownloadItem text={item.label} />
                                    ))}
                                </div>
                            </div>
                            <div className='category'>
                                <div className='title'>{t('research.page.topic-3.section-2.category-4.title')}</div>
                                <div className='results'>
                                    <DownloadItem text={catalogISTS.label} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='separator'></div>
                <div className='performances-container'>
                    <div className='performances-title'>{t('research.page.topic-4.title')}</div>
                    <div className='sections-container'>
                        <div className='performances-section'>
                            {Array.from({ length: 15 }).map((_, index) => (
                                <div className='item' key={index}>
                                    <div className='dot'></div> {t(`research.page.topic-4.section-1.item-${index + 1}`)}
                                </div>
                            ))}
                        </div>
                        <div className='performances-section section-2'>
                            {Array.from({ length: 7 }).map((_, index) => (
                                <div className='item' key={index}>
                                    <div className='dot'></div> {t(`research.page.topic-4.section-2.item-${index + 1}`)}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='separator'></div>

                <div className='resources-panel-container'>
                    <div className='panel'>
                        <div>{t('research.page.topic-5.section-1.title')}</div>
                        <div className='separator'></div>
                        <div className='section'>
                            {Array.from({ length: 5 }).map((_, index) => (
                                <div key={index}>
                                    <DownloadItem text={t(`research.page.topic-5.section-1.item-${index + 1}`)} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='panel'>
                        <div>{t('research.page.topic-5.section-2.title')}</div>
                        <div className='separator'></div>
                        <div className='section'>
                            {Array.from({ length: 5 }).map((_, index) => (
                                <div key={index}>
                                    <DownloadItem text={t(`research.page.topic-5.section-2.item-${index + 1}`)} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='panel'>
                        <div>{t('research.page.topic-5.section-3.title')}</div>
                        <div className='separator'></div>
                        <div className='section'>
                            {Array.from({ length: 5 }).map((_, index) => (
                                <div key={index}>
                                    <DownloadItem text={t(`research.page.topic-5.section-3.item-${index + 1}`)} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResearchPage;