import { useTranslation } from "react-i18next";
import SideMenu from "../../../components/side-menu";
import './base-2-page.scss';

const person1Image = require('../../../assets/images/base-1/person-2.png');

const gallery1 = require('../../../assets/images/base-2/gallery-1.png');
const gallery2 = require('../../../assets/images/base-2/gallery-2.png');
const gallery3 = require('../../../assets/images/base-2/gallery-3.png');

const Base2Page = () => {

    const { t } = useTranslation();

    return (
        <div className="base-1-page page">
            <SideMenu />
            <div className='page-content'>
                <div className='topic-container'>
                    <div className="title">{t('development.page.topic-2.base-2.title')}</div>
                    <div className="subtitle-content">
                        <div className="subtitle">{t('base-1.page.topic-1.item-1')}</div>
                        <div className="persons-container ">
                            <div className="person-container">
                                <img src={person1Image} />
                                <div className="person-info subtitle">
                                    <span>ACS Ing.
                                    </span>
                                    <span>Simona Giura</span>
                                </div>
                            </div>
                            <div className="person-container">
                                <img src={person1Image} />
                                <div className="person-info subtitle">
                                    <span>Ing.
                                    </span>
                                    <span> Coroianu Lenuta</span>
                                </div>
                            </div>
                            <div className="person-container">
                                <img src={person1Image} />
                                <div className="person-info subtitle">
                                    <span>Tehn.
                                    </span>
                                    <span>Valile Daniel Corneliu</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="separator"></div>
                    <div className="content-section">
                        <p className="subtitle">{t('base-2.page.topic-1.item-1')}</p>
                        <p className="normal-text">{t('base-2.page.topic-1.item-2')}</p>
                        <p className="normal-text">{t('base-2.page.topic-1.item-3')}</p>
                    </div>
                    <div className="subtitle-content">
                        <p className="subtitle">{t('base-2.page.topic-2.item-1')}</p>
                        <ul>
                            <li><p>{t('base-2.page.topic-2.item-2')}</p></li>
                            <li><p>{t('base-2.page.topic-2.item-3')}</p></li>
                            <li><p>{t('base-2.page.topic-2.item-4')}</p></li>
                            <li><p>{t('base-2.page.topic-2.item-5')}</p></li>
                            <li><p>{t('base-2.page.topic-2.item-6')}</p></li>
                        </ul>
                    </div>

                    <div className="subtitle-content">
                        <p className="subtitle">{t('base-2.page.topic-3.item-1')}</p>
                        <ul>
                            <li><p>{t('base-2.page.topic-3.item-2')}</p></li>
                            <li><p>{t('base-2.page.topic-3.item-3')}</p></li>
                            <li><p>{t('base-2.page.topic-3.item-4')}</p></li>
                            <li><p>{t('base-2.page.topic-3.item-5')}</p></li>
                            <li><p>{t('base-2.page.topic-3.item-6')}</p></li>
                            <li><p>{t('base-2.page.topic-3.item-7')}</p></li>
                            <li><p>{t('base-2.page.topic-3.item-8')}</p></li>
                        </ul>
                    </div>

                    <div className="subtitle-content">
                        <p className="subtitle">{t('base-2.page.topic-4.item-1')}</p>
                        <ul>
                            <li><p>{t('base-2.page.topic-4.item-2')}</p></li>
                            <li><p>{t('base-2.page.topic-4.item-3')}</p></li>
                        </ul>
                    </div>
                    <div className="subtitle-content">
                        <p className="subtitle">{t('base-2.page.topic-5.item-1')}</p>

                        <p className="normal-text">{t('base-2.page.topic-5.item-2')}</p>
                        <p className="normal-text">{t('base-2.page.topic-5.item-3')}</p>
                        <p className="normal-text">{t('base-2.page.topic-5.item-4')}</p>
                        <p className="normal-text">{t('base-2.page.topic-5.item-5')}</p>

                        <p className="normal-text">{t('base-2.page.topic-5.item-6')}</p>

                        <ul>
                            <li><p>{t('base-2.page.topic-5.item-7')}</p></li>
                            <li><p>{t('base-2.page.topic-5.item-8')}</p></li>
                            <li><p>{t('base-2.page.topic-5.item-9')}</p></li>
                            <li><p>{t('base-2.page.topic-5.item-10')}</p></li>
                            <li><p>{t('base-2.page.topic-5.item-11')}</p></li>
                            <li><p>{t('base-2.page.topic-5.item-12')}</p></li>
                            <li><p>{t('base-2.page.topic-5.item-13')}</p></li>
                        </ul>
                    </div>
                </div>
                <div className="subtitle">{t('base-2.page.topic-6.item-1')}</div>
                <div className="images-container">
                    <img src={gallery1} />
                    <img src={gallery2} />
                    <img src={gallery3} />
                </div>
            </div>
        </div>
    )
}

export default Base2Page;