import { useTranslation } from "react-i18next";

const Laboratory1PagePartPersonal: React.FC = () => {

    const { t } = useTranslation();


    return (
        <>
            <div className="section-content">
                <p> {t('laboratory-1.page.section.personal.topic-1.item-1')} </p>
             
            </div>
          
            <div className="section-content">
                <p>{t('laboratory-1.page.section.personal.topic-2.item-1')}</p>
            </div>

        </>)
}

export default Laboratory1PagePartPersonal;