import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow-white.svg';
import "./contact-form.scss";

const ContactForm: React.FC = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form Data:", formData);
  };

  return (
    <div className="contact-form-container">
      <h2 className="form-title">{t("element.contact-form.title")}</h2>
      <p className="form-description">{t("element.contact-form.description")}</p>

      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-row">
          <input
            type="text"
            name="name"
            placeholder={t("element.contact-form.name-placeholder")}
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder={t("element.contact-form.phone-placeholder")}
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-row">
          <input
            type="email"
            name="email"
            placeholder={t("element.contact-form.email-placeholder")}
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="subject"
            placeholder={t("element.contact-form.subject-placeholder")}
            value={formData.subject}
            onChange={handleChange}
            required
          />
        </div>

        <textarea
          name="message"
          placeholder={t("element.contact-form.message-placeholder")}
          rows={5}
          value={formData.message}
          onChange={handleChange}
          required
        />

        <button type="submit" className="submit-btn">
          {t("element.contact-form.submit-button")}
          <ArrowIcon />
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
