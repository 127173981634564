import { RouteObject } from "react-router-dom";
import DevelopmentPage from "./development-page";
import Base1Page from "./base-1/base-1-page";
import Base2Page from "./base-2/base-2-page";

export const developmentRouter: RouteObject[] = [
    {
        path: '',
        element: <DevelopmentPage/>
    }, 
    {
        path: 'base-1',
        element:  <Base1Page/>
    },
    {
        path: 'base-2',
        element:  <Base2Page/>
    }
]