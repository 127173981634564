import { ReactComponent as LogoBaneasa } from '../assets/icons/logo-baneasa.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import { ReactComponent as BurgerMenu } from '../assets/icons/buger-menu.svg';
import './app-mobile-header.scss';
import HeaderMobileInfoSection from './header-mobile-info-section';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const AppMobileHeader = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    const navigation = [
        {
            label: t('header.navigation-1'),
            path: '/home'
        },
        {
            label: t('header.navigation-2'),
            path: '/about-us'
        },
        {
            label: t('header.navigation-3'),
            path: '/research'
        },
        {
            label: t('header.navigation-4'),
            subPaths: [
                {
                    label: `- ${t('header.navigation-4.item-1')}`,
                    path: '/development'
                },
                {
                    label: `- ${t('header.navigation-4.item-2')}`,
                    path: '/development/base-1'
                },
                {
                    label: `- ${t('header.navigation-4.item-3')}`,
                    path: '/development/base-2'
                },
            ]
        },
        {
            label: t('header.navigation-5'),
            path: '/home'
        },
        {
            label: t('header.navigation-6'),
            path: '/events'
        },
        {
            label: t('header.navigation-7'),
            path: '/services'
        },
        {
            label: t('header.navigation-8'),
            path: '/announcements'
        },
        {
            label: 'CONTACT',
            path: '/contact'
        }
    ];

    return (
        <div className="app-mobile-header">
            <div className="logo-menu-container">
                <div className="logo">
                    <LogoBaneasa width={90} height={90} />
                </div>
                <div className="search-burger-menu-container">
                    {/* <div className="search">
                        <SearchIcon />
                    </div> */}
                    <div className="burger-menu" onClick={toggleMenu}>
                        <BurgerMenu />
                    </div>
                </div>
            </div>
            <div className={`mobile-navigation ${isMenuOpen ? 'open' : 'closed'}`}>
                <div className="close-button" onClick={toggleMenu}>
                    ✕
                </div>
                {navigation.map((navItem, index) => (
                    <div key={index} className="nav-item">
                        <div
                            className="main-item"
                            onClick={() => {
                                if (!navItem.subPaths) {
                                    navigate(navItem.path);
                                    setIsMenuOpen(false);
                                }
                            }}
                        >
                            {navItem.label}
                        </div>
                        {navItem.subPaths && (
                            <div className="subpaths-container">
                                {navItem.subPaths.map((subPath, subIndex) => (
                                    <div
                                        key={subIndex}
                                        className="subpath-item"
                                        onClick={() => {
                                            navigate(subPath.path);
                                            setIsMenuOpen(false);
                                        }}
                                    >
                                        {subPath.label}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <HeaderMobileInfoSection />
        </div>
    );
};

export default AppMobileHeader;
