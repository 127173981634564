import { useTranslation } from "react-i18next";
import SideMenu from "../../components/side-menu";
import DownloadButton from "../../components/download-button";
import './about-us-page.scss';


const aboutUsContent = require('../../assets/images/about-us-content.png');

const AboutUsPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="about-us-page page">
            <SideMenu />
            <div className="page-content">
                <img className="img-content" src={aboutUsContent} />

                <div className="topic-container">
                    <div className="title">{t('about-us.page.baneasa-history.title')}</div>
                    <div className="section-content">
                        <p>{t('about-us.page.baneasa-history.part1')}</p>
                        <p>{t('about-us.page.baneasa-history.part2')}</p>
                        <p>{t('about-us.page.baneasa-history.part3')}</p>
                        <p>{t('about-us.page.baneasa-history.part4')}</p>
                        <p>{t('about-us.page.baneasa-history.part5')}</p>
                        <p>{t('about-us.page.baneasa-history.part6')}</p>
                        <p>{t('about-us.page.baneasa-history.part7')}</p>
                    </div>
                </div>
                <div className="topic-container">
                    <div className="dropdown-title">{t('about-us.page.topic-1.title')}</div>
                    <div className="section-content">
                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-1.subtitle')}</p>
                            <p>{t('about-us.page.topic-1.strategies')}</p>
                        </div>
                        <ul>
                            <li><p>{t('about-us.page.topic-1.strategies.strategy-1')}</p></li>
                            <li><p>{t('about-us.page.topic-1.strategies.strategy-2')}</p></li>
                            <li><p>{t('about-us.page.topic-1.strategies.strategy-3')}</p></li>
                        </ul>
                        <p className="subtitle">{t('about-us.page.topic-1.general-objectives')}</p>
                        <ul>
                            <li><p>{t('about-us.page.topic-1.general-objective.objective-1')}</p></li>
                            <li><p>{t('about-us.page.topic-1.general-objective.objective-2')}</p></li>
                            <li><p>{t('about-us.page.topic-1.general-objective.objective-3')}</p></li>
                            <li><p>{t('about-us.page.topic-1.general-objective.objective-4')}</p></li>
                            <li><p>{t('about-us.page.topic-1.general-objective.objective-5')}</p></li>
                            <li><p>{t('about-us.page.topic-1.general-objective.objective-6')}</p></li>
                            <li><p>{t('about-us.page.topic-1.general-objective.objective-7')}</p></li>
                        </ul>
                        <DownloadButton theme="white" label={t('about-us.page.topic-1.download-button')} />
                    </div>
                </div>
                <div className="topic-container">
                    <div className="dropdown-title">{t('about-us.page.topic-2.title')}</div>
                    <div className="section-content">
                        <ul>
                            <li><p>{t('about-us.page.topic-2.part-1')}</p></li>
                            <li><p>{t('about-us.page.topic-2.part-2')}</p></li>
                            <li><p>{t('about-us.page.topic-2.part-3')}</p></li>
                            <li><p>{t('about-us.page.topic-2.part-4')}</p></li>
                        </ul>
                    </div>
                </div>
                <div className="topic-container">
                    <div className="dropdown-title">{t('about-us.page.topic-3.title')}</div>
                    <div className="subtitle-content">
                        <p className="subtitle">{t('about-us.page.topic-3.management-structure')}</p>
                        <ul>
                            <li><p>{t('about-us.page.topic-3.management-structure.management-1')}</p></li>
                            <li><p>{t('about-us.page.topic-3.management-structure.management-2')}</p></li>
                            <li><p>{t('about-us.page.topic-3.management-structure.management-3')}</p></li>
                        </ul>
                    </div>
                    <div className="subtitle-content">
                        <p className="subtitle">{t('about-us.page.topic-3.management-structure')}</p>
                        <ul>
                            <li><p>{t('about-us.page.topic-3.management-structure.management-1')}</p></li>
                            <li><p>{t('about-us.page.topic-3.management-structure.management-2')}</p></li>
                            <li><p>{t('about-us.page.topic-3.management-structure.management-3')}</p></li>
                        </ul>
                    </div>
                    <div className="subtitle-content">
                        <p className="subtitle">{t('about-us.page.topic-3.directors-board')}</p>
                        <ul>
                            <li><p>{t('about-us.page.topic-3.directors-board.director-1')}</p></li>
                        </ul>
                    </div>
                    <div className="subtitle-content">
                        <p className="subtitle">{t('about-us.page.topic-3.members')}</p>
                        <ul>
                            <li><p>{t('about-us.page.topic-3.members.member-1')}</p></li>
                            <li><p>{t('about-us.page.topic-3.members.member-2')}</p></li>
                            <li><p>{t('about-us.page.topic-3.members.member-3')}</p></li>
                        </ul>
                    </div>
                    <div className="subtitle-content">
                        <p className="subtitle">{t('about-us.page.topic-3.responsible')}</p>
                        <ul>
                            <li><p>{t('about-us.page.topic-3.responsible.person')}</p></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsPage;