import InfoContactCard from './info-contact-card';
import InfoRequestPublicInterest from './info-request-public-interest';
import { ReactComponent as LeafIcon } from '../assets/icons/leaf.svg';
import { ReactComponent as PdfIcon } from '../assets/icons/pdf.svg';
import { ReactComponent as DownloadIcon } from '../assets/icons/download.svg';
import './side-menu.scss';
import { useTranslation } from 'react-i18next';
import DownloadButton from './download-button';

interface SideMenuProps {
    showPublicInterest?: boolean
}

const SideMenu: React.FC<SideMenuProps> = ({showPublicInterest = true}) => {
    const { t } = useTranslation();
    return (
        <div className='side-menu-container'>
            { showPublicInterest && <InfoRequestPublicInterest />}
            <InfoContactCard />
            <div className='about-baneasa-container'>
                <div className='item title'>
                    <LeafIcon width={20} height={20} />
                    <span>{t('side-menu.about-baneasa.title')}</span>
                </div>
                <DownloadButton theme='white' label={t('side-menu.button.about-us')}/>
                {/* <DownloadButton theme='yellow' label={t('side-menu.button.catalog')}/> */}
            </div>
        </div>
    )
}
export default SideMenu;