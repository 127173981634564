import { useTranslation } from "react-i18next";
import SideMenu from "../../../components/side-menu";
import './laboratory-1-page.scss'
import { ReactNode, useMemo, useState } from "react";
import Laboratory1PagePartDescription from "./laboratory-1-page.part.description";
import Laboratory1PagePartPersonal from "./laboratory-1-page.part.personal";
import Laboratory1PagePartProjects from "./laboratory-1-page.part.projects";
import Laboratory1PagePartGallery from "./laboratory-1-page.part.gallery";
import Laboratory1PagePartAchievements from "./laboratory-1-page.part.achievements";

interface Topic {
    label: string,
    value: ReactNode
}

const Laboratory1Page: React.FC = () => {

    const { t } = useTranslation();
    const subMenuItems = useMemo(() => [
        { label: t('laboratory.submenu.item-1'), value: <Laboratory1PagePartDescription/> },
        { label: t('laboratory.submenu.item-2'), value: <Laboratory1PagePartPersonal/> },
        { label: t('laboratory.submenu.item-3'), value: <Laboratory1PagePartProjects/> },
        { label: t('laboratory.submenu.item-4'), value: <Laboratory1PagePartGallery/> },
        { label: t('laboratory.submenu.item-5'), value: <Laboratory1PagePartAchievements/> }
    ], [t]);
    const [activeTopic, setActiveTopic] = useState<Topic>(subMenuItems[0]);

    const handleChangeTopic = (topic: Topic) => {
        setActiveTopic(topic)
    }


    return (
        <div className="laboratory-1-page page">
            <SideMenu />
            <div className="page-content">
                <div className="topic-container">
                    <div className="title">{t('laboratory-1.page.title')}</div>

                    <div className="submenu-container">
                        {
                            subMenuItems.map((item, index) => (
                                <button
                                    className={`submenu-item ${activeTopic.value === item.value ? 'active ' : ''}`}
                                    onClick={() => handleChangeTopic(item)}
                                    key={index}>
                                    {item.label}
                                </button>
                            ))
                        }
                    </div>
                    {activeTopic.value}
                </div>
            </div>
        </div>
    )
}

export default Laboratory1Page;