import { useTranslation } from "react-i18next";

const Laboratory2PagePartAchievements: React.FC = () => {

    const { t } = useTranslation();
    return (
        <>
            <div className="section-content">
                <ul>
                    <li><p>{t('laboratory-2.page.section.achievements.topic-1.item-1')}</p></li>
                    <li><p>{t('laboratory-2.page.section.achievements.topic-1.item-2')}</p></li>
                    <li><p>{t('laboratory-2.page.section.achievements.topic-1.item-3')}</p></li>
                    <li><p>{t('laboratory-2.page.section.achievements.topic-1.item-4')}</p></li>
                    <li><p>{t('laboratory-2.page.section.achievements.topic-1.item-5')}</p></li>
                    <li><p>{t('laboratory-2.page.section.achievements.topic-1.item-6')}</p></li>
                    <li><p>{t('laboratory-2.page.section.achievements.topic-1.item-7')}</p></li>
                    <li><p>{t('laboratory-2.page.section.achievements.topic-1.item-8')}</p></li>
                </ul>
            </div>
            <div className="section-content">
                <p>{t('laboratory-2.page.section.achievements.topic-2.item-1')}</p>
                <p>{t('laboratory-2.page.section.achievements.topic-2.item-2')}</p>
                <ul>
                    <li><p>{t('laboratory-2.page.section.achievements.topic-2.item-3')}</p></li>
                    <li><p>{t('laboratory-2.page.section.achievements.topic-2.item-4')}</p></li>
                </ul>
            </div>
            <div className="section-content">
                <p>{t('laboratory-2.page.section.achievements.topic-3.item-1')}</p>
                <p>{t('laboratory-2.page.section.achievements.topic-3.item-2')}</p>
                <ul>
                    <li><p>{t('laboratory-2.page.section.achievements.topic-3.item-3')}</p></li>
                    <li><p>{t('laboratory-2.page.section.achievements.topic-3.item-4')}</p></li>
                </ul>
            </div>
            <p className="normal-text">{t('laboratory-2.page.section.achievements.topic-4.item-1')}</p>
        </>
    )
}

export default Laboratory2PagePartAchievements;