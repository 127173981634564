import { useState, ReactNode, useEffect, useRef } from 'react';
import './dropdown-menu.scss';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';

interface DropdownMenuProps {
    label: string;
    items: { label: string; onClick: () => void }[];
}

const DropdownMenu = ({ label, items }: DropdownMenuProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div 
            className="dropdown-menu" 
            onClick={() => setIsOpen(!isOpen)} 
            ref={dropdownRef}
        >
            <div className="dropdown-label">
                <span>{label}</span>
            </div>
            {isOpen && (
                <div className="dropdown-content">
                    {items.map((item, index) => (
                        <div key={index} className="dropdown-item" onClick={item.onClick}>
                            {item.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DropdownMenu;
