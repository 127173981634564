import { useTranslation } from "react-i18next";

const Laboratory2PagePartProjects: React.FC = () => {

    const { t } = useTranslation();

    return (
        <>
            <p className="normal-text"> {t('laboratory-2.page.section.projects.topic-1.item-1')} </p>

            <div className="section-content">
                <p className="normal-text"> {t('laboratory-2.page.section.projects.topic-1.item-2')} </p>
                <p className="normal-text"> {t('laboratory-2.page.section.projects.topic-1.item-3')} </p>
                <ul>
                    <li><p>{t('laboratory-2.page.section.projects.topic-1.item-4')}</p></li>
                </ul>
                <p className="normal-text"> {t('laboratory-2.page.section.projects.topic-1.item-5')} </p>
                <ul>
                    <li><p>{t('laboratory-2.page.section.projects.topic-1.item-6')}</p></li>
                </ul>
                <p className="normal-text"> {t('laboratory-2.page.section.projects.topic-1.item-7')} </p>
            </div>
            <p className="subtitle"> {t('laboratory-2.page.section.projects.topic-2.item-1')} </p>
            <p className="normal-text"> {t('laboratory-2.page.section.projects.topic-2.item-2')} </p>
            <p className="normal-text"> {t('laboratory-2.page.section.projects.topic-2.item-3')} </p>
        </>
    )
}

export default Laboratory2PagePartProjects;