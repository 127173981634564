import { useTranslation } from "react-i18next";
import { ReactComponent as LeafIcon } from '../assets/icons/leaf.svg';
import './info-request-public-interest.scss';

const InfoRequestPublicInterest = () => {
    const { t } = useTranslation();
    return (
        <div className="info-request-public-interest-container">
            <div className="title-wrapper">
                <LeafIcon width={30} height={30} />
                <span className="title">{t('side-menu.public-interest.title')}</span>
            </div>
            <div className="content">
                <div className="item">
                    <span className="bolded">{t('side-menu.public-interest.subtitle')}</span>
                </div>
                <div className="item">
                    <span className="bolded">{t('side-menu.public-interest.office-hours.header')}</span>
                    <span>{t('side-menu.public-interest.office-hours.info')}</span>
                </div>
                {/* <div className="item">
                    <span className="bolded">{t('side-menu.public-interest.office-hours.header')}</span>
                    <span>{t('side-menu.public-interest.office-hours.info')}</span>
                </div> */}
                <div className="item">
                    <span className="bolded">{t('side-menu.public-interest.visiting-hours.header')}</span>
                    <span>{t('side-menu.public-interest.visiting-hours.info')}</span>
                </div>
                <div className="item">
                    <span className="bolded">{t('side-menu.public-interest.headquarters.header')}</span>
                    <span>{t('side-menu.public-interest.headquarters.info')}</span>
                </div>
                <div className="item row">
                    <span className="bolded">{t('side-menu.public-interest.phone.header')}</span>
                    <span>{t('side-menu.public-interest.phone.info')}</span>
                </div>
                <div className="item row">
                    <span className="bolded">{t('side-menu.public-interest.email.header')}</span>
                    <span>{t('side-menu.public-interest.email.info')}</span>
                </div>
                <div className="item row">
                    <span className="bolded">{t('side-menu.public-interest.site.header')}</span>
                    <span>{t('side-menu.public-interest.site.info')}</span>
                </div>
            </div>
        </div>
    )
}

export default InfoRequestPublicInterest;