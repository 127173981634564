import { ReactComponent as PdfIcon } from '../assets/icons/pdf-small.svg';
import { ReactComponent as DownloadIcon } from '../assets/icons/download-small.svg';
import './download-item.scss';

interface DownloadItemProps {
    text: string;
}

const DownloadItem: React.FC<DownloadItemProps> = ({ text }) => {
    return (
        <div className='download-item'>
            <div>
                <PdfIcon />
            </div>
            <span>{text}</span>
            <div>
                <DownloadIcon />
            </div>
        </div>
    );
}

export default DownloadItem;