import { useTranslation } from "react-i18next";

const Laboratory2PagePartPersonal: React.FC = () => {

    const { t } = useTranslation();


    return (
        <>
            <div className="section-content">
                <p> {t('laboratory-2.page.section.personal.topic-1.item-1')} </p>
                <ul>
                    <li><p>{t('laboratory-2.page.section.personal.topic-1.item-2')}</p></li>
                    <li><p>{t('laboratory-2.page.section.personal.topic-1.item-3')}</p></li>
                </ul>
            </div>
          
            <div className="section-content">
                <p>{t('laboratory-2.page.section.personal.topic-2.item-1')}</p>
                <ul>
                    <li><p>{t('laboratory-2.page.section.personal.topic-2.item-2')}</p></li>
                    <li><p>{t('laboratory-2.page.section.personal.topic-2.item-3')}</p></li>
                </ul>

                <p>{t('laboratory-2.page.section.personal.topic-3.item-1')}</p>
                <ul>
                    <li><p>{t('laboratory-2.page.section.personal.topic-3.item-2')}</p></li>
                    <li><p>{t('laboratory-2.page.section.personal.topic-3.item-3')}</p></li>
                </ul>

                <p>{t('laboratory-2.page.section.personal.topic-4.item-1')}</p>
                <ul>
                    <li><p>{t('laboratory-2.page.section.personal.topic-4.item-2')}</p></li>
                    <li><p>{t('laboratory-2.page.section.personal.topic-4.item-3')}</p></li>
                </ul>

                <p>{t('laboratory-2.page.section.personal.topic-5.item-1')}</p>
                <ul>
                    <li><p>{t('laboratory-2.page.section.personal.topic-5.item-2')}</p></li>
                    <li><p>{t('laboratory-2.page.section.personal.topic-5.item-3')}</p></li>
                </ul>
            </div>

        </>)
}

export default Laboratory2PagePartPersonal;