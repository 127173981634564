import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import AppLayout from "../components/app-layout";
import HomePage from "./home/home-page";
import AboutUsPage from "./about-us/about-us-page";
import ResearchPage from "./research/research-page";
import { researchRouter } from "./research/research-router";
import { developmentRouter } from "./development/development-router";
import ServicesPage from "./services/services-page";
import ContactPage from "./contact/contact-page";
import AnnouncementsPage from "./announcements/announcements-page";
import { eventsRouter } from "./events/events-router";

const appRouter = createBrowserRouter([
    {
        path: '',
        element: <AppLayout/>,
        children: [
            {
                path: '',
                element: <Navigate to="home" replace /> 
            },
            {
                path: 'home',
                element: <HomePage/>
            }, 
            {
                path: '/about-us',
                element: <AboutUsPage/>
            }, 
            {
                path: '/research',
                children: researchRouter
            },
            {
                path: '/development',
                children: developmentRouter,
            },
            {
                path: '/shop'
            }, 
            {
                path: '/events',
                children: eventsRouter
            }, 
            {
                path: '/services',
                element: <ServicesPage/>
            },
            {
                path: '/announcements',
                element: <AnnouncementsPage/>
            },
            {
                path: '/contact',
                element: <ContactPage/>
            }
        ]
    }
])



const AppRouter: React.FC = () => {
    return ( 
        <RouterProvider router={appRouter}/>
    )
}

export default AppRouter;