import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as ActiveArrow } from '../../../assets/icons/arrow-active.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-green.svg';
import { ReactComponent as LeafIcon } from '../../..//assets/icons/leaf.svg';
import './national-projects.side-menu.subprojects.scss';

const SideMenuProjects: React.FC = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const currentPath = location.pathname.split('/');
    const currentPlan = currentPath[2]?.split('-')[2] || '1';  
    const projects = Object.entries(t(`side-menu.national-projects.plan-${currentPlan}`, { returnObjects: true }) as Record<string, any>)
        .map(([key, project]) => ({
            id: key.split('-')[1],  
            title: project.title,
            description: project.description
        }));
        
    return (
        <div className="side-menu-sub-projects-container">
            <div className="title-wrapper">
                <LeafIcon width={30} height={30} />
                <span className="title">{t('side-menu.national-projects.title')}</span>
            </div>
            <div className="content">
                {projects.map((project) => {
                    const isActive = location.pathname.includes(`/national-projects-2/${project.id}`);
                    return (
                        <div
                            key={project.id}
                            className={`item ${isActive ? 'active' : ''}`}
                            onClick={() => navigate(`/research/national-projects-2/${project.id}`)}
                        >
                            <div className="text-container">
                                <span className="bolded">{project.title}</span>
                                <span>{project.description}</span>
                            </div>
                            <div>
                                {isActive ? <ActiveArrow width={25} height={25} /> : <ArrowIcon width={25} height={25} />}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SideMenuProjects;
