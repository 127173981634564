import ContactForm from '../../components/contact-form';
import SideMenu from '../../components/side-menu';
import { ReactComponent as PdfIcon } from '../../assets/icons/pdf-small.svg';
import './announcements-page.scss';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const AnnouncementsPage = () => {
    const { t } = useTranslation();
    const [expandedRow, setExpandedRow] = useState<number | null>(null);

    const announcements = [
        {
            name: t('announcements.page.table.row-1.name'),
            date: '29.10.2024',
            observation: t('announcements.page.table.row-1.observation'),
            file: '/files/announcement1.pdf'
        },
        {
            name: t('announcements.page.table.row-2.name'),
            date: '29.10.2024',
            observation: t('announcements.page.table.row-2.observation'),
            file: '/files/announcement2.pdf'
        },
        {
            name: t('announcements.page.table.row-3.name'),
            date: '29.10.2024',
            observation: t('announcements.page.table.row-3.observation'),
            file: '/files/announcement3.pdf'
        },
        {
            name: t('announcements.page.table.row-4.name'),
            date: '29.10.2024',
            observation: t('announcements.page.table.row-4.observation'),
            file: '/files/announcement4.pdf'
        },
        {
            name: t('announcements.page.table.row-5.name'),
            date: '29.10.2024',
            observation: t('announcements.page.table.row-5.observation'),
            file: '/files/announcement5.pdf'
        }
    ];

    const toggleRow = (index: number) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    return (
        <div className="announcements-page page">
            <SideMenu />
            <div className="page-content">
                <div className="topic-container">
                    <div className="title">{t('announcements.page.title')}</div>
                    <div className="announcements-table">
                        {announcements.map((item, index) => (
                            <div
                                key={index}
                                className={`announcement-row ${
                                    expandedRow === index ? 'expanded' : ''
                                }`}
                            >
                                <div
                                    className="row-header"
                                    onClick={() => toggleRow(index)}
                                >
                                    {item.name}
                                    <span className="toggle-icon">
                                        {expandedRow === index ? '▲' : '▼'}
                                    </span>
                                </div>
                                {expandedRow === index && (
                                    <div className="row-details">
                                        <div>
                                            <strong>{t('announcements.page.table.header.date')}</strong>
                                            <span>{item.date}</span>
                                        </div>
                                        <div>
                                            <strong>{t('announcements.page.table.header.observation')}</strong>
                                            <span>{item.observation}</span>
                                        </div>
                                        <div>
                                            <strong>{t('announcements.page.table.header.download')}</strong>
                                                <PdfIcon />
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <ContactForm />
            </div>
        </div>
    );
};

export default AnnouncementsPage;
