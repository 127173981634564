import './app-header.scss';
import { ReactComponent as LogoBaneasa } from '../assets/icons/logo-baneasa.svg';
import { ReactComponent as PhoneIcon } from '../assets/icons/phone.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';
import HeaderInfoSection from './header-info-section';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DropdownMenu from './dropdown-menu';
import { useTranslation } from 'react-i18next';




const AppHeader = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [backgroundImage, setBackgroundImage] = useState();
    const location = useLocation();
    const isHome = location.pathname === '/home';
    const handleNavigate = (path: string) => {
        navigate(path);
    }

    const navigation = [
        {
            label: t('header.navigation-1'),
            path: '/home'
        },
        {
            label: t('header.navigation-2'),
            path: '/about-us'
        },
        {
            label: t('header.navigation-3'),
            path: 'research'
        },
        {
            label: t('header.navigation-4'),
            subPaths: [
                {
                    label: t('header.navigation-4.item-1'),
                    path: 'development'
                },
                // {
                //     label: t('header.navigation-4.item-2'),
                //     path: 'development/base-1'
                // },
                // {
                //     label: t('header.navigation-4.item-3'),
                //     path: 'development/base-2'
                // },
            ]
        },
        {
            label: t('header.navigation-5'),
            path: '/home'
        },
        {
            label: t('header.navigation-6'),
            path: '/events'
        },
        {
            label: t('header.navigation-7'),
            path: '/services'
        },
        {
            label: t('header.navigation-8'),
            path: '/announcements'
        }
    ]

    useEffect(() => {
        try {
            const path = location.pathname;
            const pathSegments = path.split('/').filter(Boolean);
            if (pathSegments.length >= 3 && pathSegments[1].startsWith('national-projects')) {
                const projectSegment = pathSegments[1];
                const lastSegment = pathSegments[pathSegments.length - 1];

                const imagePath = require(`../assets/images/${projectSegment}/${lastSegment}-bg.png`);
                setBackgroundImage(imagePath);
            } else if (pathSegments.length >= 3 && pathSegments[1] === 'workshop') {
                const workshopKey = `workshop-${pathSegments[2]}`; 
                const lastSegment = pathSegments[pathSegments.length - 1]; 
                const resolvedImagePath = require(`../assets/images/${workshopKey}/${lastSegment}-bg.png`);
                setBackgroundImage(resolvedImagePath);

            }
            else {
                const lastSegment = path.substring(path.lastIndexOf('/') + 1);
                const originalImagePath = require(`../assets/images/${lastSegment}-bg.png`);

                setBackgroundImage(originalImagePath);
            }
        } catch (e) {
            console.log(e);

        }
    }, [location.pathname]);

    return (
        <header className={`app-header ${isHome ? 'home' : ''} `}  >
            <div className='overlay' style={{ background: `url(${backgroundImage})` }}>
                <div className='navbar-section'>
                    <div className='logo-container'>
                        <LogoBaneasa />
                    </div>
                    <div className='navbar-container'>
                        <div className="navigation">
                            {navigation.map((navItem, index) => (
                                <div key={index} className="nav-item">
                                    {navItem.subPaths ? (
                                        <DropdownMenu
                                            label={navItem.label}
                                            items={navItem.subPaths.map((subItem) => ({
                                                label: subItem.label,
                                                onClick: () => handleNavigate(subItem.path)
                                            }))}
                                        />
                                    ) : (
                                        <span onClick={() => handleNavigate(navItem.path)}>
                                            {navItem.label}
                                        </span>
                                    )}

                                    {index !== navigation.length - 1 && <div className="yellow-dot"></div>}
                                </div>
                            ))}
                        </div>
                        <div className='call-search-container'>
                            <PhoneIcon />
                            <div className='call-text-container'>
                                <span>+40 721 567 890</span>
                            </div>
                        </div>
                    </div>
                    <div className='contact-us-container' onClick={() => { navigate('/contact') }}>
                        <div className='button-container'>
                            <span >Contact</span>
                            <ArrowIcon />
                        </div>
                    </div>
                </div>
                <HeaderInfoSection />
            </div>
        </header>
    )
}
export default AppHeader;
