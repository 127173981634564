import { RouteObject } from "react-router-dom";
import ResearchPage from "./research-page";
import Laboratory1Page from "./laboratory-1/laboratory-1-page";
import Laboratory2Page from "./laboratory-2/laboratory-2-page";
import Laboratory3Page from "./laboratory-3/laboratory-3-page";
import { nationalProjects1Router } from "./national-projects-1/national-projects-1-router";
import { nationalProjects2Router } from "./national-projects-2/national-projects-2-router";

export const researchRouter: RouteObject[] = [
    {
        path: '',
        element: <ResearchPage/>
    }, 
    {
        path: 'laboratory-1',
        element: <Laboratory1Page/>
    },
    {
        path: 'laboratory-2',
        element: <Laboratory2Page/>
    },
    {
        path: 'laboratory-3',
        element: <Laboratory3Page/>
    },
    {
        path: 'national-projects-1',
        children: nationalProjects1Router
    },
    {
        path: 'national-projects-2',
        children: nationalProjects2Router
    }
]