import { useTranslation } from "react-i18next";

const Laboratory3PagePartDescription: React.FC = () => {
    const { t } = useTranslation();


    return (
        <>
            <p className="normal-text"> {t('laboratory-2.page.section.description.topic-1')} </p>
            <p className="normal-text"> {t('laboratory-2.page.section.description.topic-2.item-1')} </p>
            <div className="section-content">
                <p className="normal-text">{t('laboratory-2.page.section.description.topic-2.item-2')}</p>
                <ul>
                    <li><p>{t('laboratory-2.page.section.description.topic-2.item-3')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-2.item-4')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-2.item-5')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-2.item-6')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-2.item-7')}</p></li>
                </ul>
            </div>
            <p className="normal-text"> {t('laboratory-2.page.section.description.topic-3.item-1')} </p>
            <p className="normal-text">{t('laboratory-2.page.section.description.topic-3.item-2')}</p>
            <div className="section-content">
                <p className="normal-text">{t('laboratory-2.page.section.description.topic-4.item-1')}</p>
                <ul>
                    <li><p>{t('laboratory-2.page.section.description.topic-4.item-2')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-4.item-3')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-4.item-4')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-4.item-5')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-4.item-6')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-4.item-7')}</p></li>
                </ul>
            </div>
            <div className="section-content">
                <p className="normal-text">{t('laboratory-2.page.section.description.topic-5.item-1')}</p>
                <ul>
                    <li><p>{t('laboratory-2.page.section.description.topic-5.item-2')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-5.item-3')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-5.item-4')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-5.item-5')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-5.item-6')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-5.item-7')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-5.item-8')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-5.item-9')}</p></li>
                </ul>
            </div>
            <div className="section-content">
                <p>{t('laboratory-2.page.section.description.topic-6.item-1')}</p>
                <ul>
                    <li><p>{t('laboratory-2.page.section.description.topic-6.item-2')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-6.item-3')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-6.item-4')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-6.item-5')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-6.item-6')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-6.item-7')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-6.item-8')}</p></li>
                    <li><p>{t('laboratory-2.page.section.description.topic-6.item-9')}</p></li>
                </ul>
            </div>
            <p className="normal-text">{t('laboratory-2.page.section.description.topic-7.item-1')}</p>
            
        </>
    )
}

export default Laboratory3PagePartDescription;