import './laboratory-2-page.part.gallery.scss';

const Laboratory2PagePartGallery: React.FC = () => {
    return (
    <>
    <div className='gallery-container'>
    {Array.from({length: 15}).map((_,index) => {
        const image = require(`../../../assets/images/laboratory-2/laboratory-2-gallery-${index+1}.png`)
       return <img key={index} src={image} />
    })}
    </div>
    </>
    )
}

export default Laboratory2PagePartGallery;