import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SideMenu from "../../../components/side-menu";
import "./workshop.layout.scss";

const galleryCounts: { [key: string]: number } = {
    "1": 2, 
    "2": 1, 
    "3": 3, 
    "4": 1  
};

const WorkshopLayout: React.FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const workshopKey = `workshop-${id}`;

    const content: any = t(workshopKey, { returnObjects: true });

    const galleryImages = Array.from(
        { length: galleryCounts[id || "0"] || 0 },
        (_, index) => require(`../../../assets/images/workshop-${id}/gallery-${index + 1}.png`)
    );

    if (!content) {
        return <div>Workshop content not found</div>;
    }

    return (
        <div className="workshop-layout page">
            <SideMenu showPublicInterest={false} />
            <div className="page-content">
                <div className="topic-container">
                    {content.title && (
                        <div className='title'>
                            {content.title.text}
                        </div>
                    )}

                    {content.sections &&
                        content.sections.map((section: any, index: number) => {
                            if (section.type === "paragraph") {
                                return (
                                        <p className="normal-text">{section.text}</p>
                                );
                            }

                            if (section.type === "bulletPoints") {
                                return (
                                    <div key={index} className="subtitle-content">
                                        <ul>
                                            {section.items.map(
                                                (item: string, idx: number) => (
                                                    <li key={idx}>
                                                        <p>{item}</p>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                );
                            }
                        })}
                </div>
                {galleryImages.length > 0 && (
                        <div className="workshop-gallery-container">
                            {galleryImages.map((src, index) => (
                                <div>
                                <img
                                    key={index}
                                    src={src}
                                />
                                </div>
                            ))}
                        </div>
                    )}
            </div>
        </div>
    );
};

export default WorkshopLayout;
