import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import CarouselFade from '../../components/carousel-fade';
import './home-page.section.research.scss';


const researchBg = require('../../assets/images/research-bg-home.png');
const researchItem1 = require('../../assets/images/proiect-national-1.png');
const researchItem2 = require('../../assets/images/proiect-national-2.png');
const researchItem3 = require('../../assets/images/home-research-item-3.png');

interface Item {
    image: string;
    category?: string;
    title: string;
    description: string;
}

const items: Item[] = [
    {
        image: researchItem1,
        category: 'Cercetare',
        title: 'Plan sectorial MADR 2026',
        description: 'Strategia de dezvoltare a cercetării și principalele obiective ale activităților de cercetare desfășurate la SCDP Băneasa'
    },
    {
        image: researchItem2,
        category: 'Cercetare',
        title: 'PNDR - FEADR',
        description: 'Strategia de dezvoltare a cercetării și principalele obiective ale activităților de cercetare desfășurate la SCDP Băneasa'
    },
    // {
    //     image: researchItem3,
    //     category: 'LEGUME',
    //     title: 'Fertilizarea Solului',
    //     description: 'Monitorizarea nutriției și fertilizarea în sistem ecologic în plantatiile pomicole'
    // },
    // {
    //     image: researchItem1,
    //     category: 'FERTILIZATORI',
    //     title: 'Concepts in Harvesting',
    //     description: 'Testing new approved crop varieties in pilot plots'
    // },
    // {
    //     image: researchItem2,
    //     category: 'FRUCTE',
    //     title: 'Harvested Products',
    //     description: 'Processes for producing orchard planting material'
    // },
    // {
    //     image: researchItem3,
    //     category: 'LEGUME',
    //     title: 'Soil Fertilization',
    //     description: 'Monitoring nutrition and fertilizing organic orchards'
    // }
];




const HomePageSectionResearch = () => {


    
    const carouselItems = items.map((item, index) => (
        <div className='item' key={index}>
            <div className='image-container'>
                <img src={item.image} alt={item.category} />
            </div>
            <div className='category'>{item.category}</div>
            <div className='title'>{item.title}</div>
            <div className='separator'></div>
            <span className='description'>{item.description}</span>
            <div className='arrow-container'>
                <ArrowIcon />
            </div>
        </div>
    ));
    return (
        <div className="homepage-research">
            <div className='bg-image-container'>
                <img src={researchBg} alt="Background" />
            </div>
                <CarouselFade
                    theme='dark'
                    helperText='Cercetare'
                    title='Proiecte Cercetare'
                    items={carouselItems}
                />
        </div>
    );
};

export default HomePageSectionResearch;
