import { useTranslation } from "react-i18next";
import SideMenu from "../../../components/side-menu";
import './base-1-page.scss';

const person1Image = require('../../../assets/images/base-1/person-1.png');
const person2Image = require('../../../assets/images/base-1/person-2.png');

const gallery1 = require('../../../assets/images/base-1/gallery-1.png');
const gallery2 = require('../../../assets/images/base-1/gallery-2.png');
const gallery3 = require('../../../assets/images/base-1/gallery-3.png');

const Base1Page = () => {

    const { t } = useTranslation();

    return (
        <div className="base-1-page page">
            <SideMenu />
            <div className='page-content'>
                <div className='topic-container'>
                    <div className="title">{t('development.page.topic-2.base-1.title')}</div>
                    <div className="subtitle-content">
                        <div className="subtitle">{t('base-1.page.topic-1.item-1')}</div>
                        <div className="persons-container ">
                            <div className="person-container">
                                <img src={person1Image} />
                                <div className="person-info subtitle">
                                    <span>CS ing.
                                    </span>
                                    <span>DUMITRU MARIA</span>
                                </div>
                            </div>
                            <div className="person-container">
                                <img src={person2Image} />
                                <div className="person-info subtitle">
                                    <span>Tehn.
                                    </span>
                                    <span>ANDREESCU MIHAI</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="separator"></div>
                    <div className="content-section">
                        <p className="subtitle">{t('base-1.page.topic-2.item-1')}</p>

                        <p className="normal-text">{t('base-1.page.topic-2.item-2')}</p>

                    </div>
                    <div className="subtitle-content">

                        <p className="normal-text">{t('base-1.page.topic-3.item-1')}</p>
                        <ul>
                            <li><p>{t('base-1.page.topic-3.item-2')}</p></li>
                            <li><p>{t('base-1.page.topic-3.item-3')}</p></li>
                            <li><p>{t('base-1.page.topic-3.item-4')}</p></li>
                            <li><p>{t('base-1.page.topic-3.item-5')}</p></li>
                            <li><p>{t('base-1.page.topic-3.item-6')}</p></li>
                        </ul>
                    </div>

                       <div className="subtitle-content">
                        <p className="subtitle">{t('base-1.page.topic-3.item-7')}</p>
                        <ul>
                            <li><p>{t('base-1.page.topic-3.item-8')}</p></li>
                            <li><p>{t('base-1.page.topic-3.item-9')}</p></li>
                            <li><p>{t('base-1.page.topic-3.item-10')}</p></li>
                            <li><p>{t('base-1.page.topic-3.item-11')}</p></li>
                            <li><p>{t('base-1.page.topic-3.item-12')}</p></li>
                            <li><p>{t('base-1.page.topic-3.item-13')}</p></li>
                            <li><p>{t('base-1.page.topic-3.item-14')}</p></li>
                            <li><p>{t('base-1.page.topic-3.item-15')}</p></li>
                        </ul>
                    </div>

                    <div className="subtitle-content">
                        <p className="subtitle">{t('base-1.page.topic-4.item-1')}</p>
                        <ul>
                            <li><p>{t('base-1.page.topic-4.item-2')}</p></li>
                            <li><p>{t('base-1.page.topic-4.item-3')}</p></li>
                            <li><p>{t('base-1.page.topic-4.item-4')}</p></li>
                            <li><p>{t('base-1.page.topic-4.item-5')}</p></li>
                            <li><p>{t('base-1.page.topic-4.item-6')}</p></li>
                            <li><p>{t('base-1.page.topic-4.item-7')}</p></li>
                        </ul>
                    </div>
                </div>
                <div className="images-container">
                    <img src={gallery1}  />
                    <img src={gallery2}  />
                    <img src={gallery3}  />
                </div>
            </div>
        </div>
    )
}

export default Base1Page;