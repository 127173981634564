import { useTranslation } from "react-i18next";
import './laboratory-1-page.part.achievements.scss';

const imageCais = require('../../../assets/images/laboratory-1/ameliorare-cais-1.png');
const imagePiersic = require('../../../assets/images/laboratory-1/ameliorare-piersic-1.png');

const Laboratory1PagePartAchievements: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="laboratory-1-achievements">
            <p className="normal-text">{t('laboratory-1.page.section.achievements.topic-1.item-1')}</p>

            <div className="achievements-container">
                <div className="achievement-section">
                    <h3 className="normal-text">{t('laboratory-1.page.section.achievements.topic-2.item-1')}</h3>
                    <img src={imageCais} alt="Ameliorare cais" className="achievement-image" />
                    <ul className="normal-text">
                        <li>{t('laboratory-1.page.section.achievements.topic-2.item-3')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-2.item-4')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-2.item-5')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-2.item-6')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-2.item-7')}</li>
                    </ul>
                </div>

                <div className="achievement-section">
                    <h3 className="normal-text">{t('laboratory-1.page.section.achievements.topic-3.item-1')}</h3>
                    <img src={imagePiersic} alt="Ameliorare piersic" className="achievement-image" />
                    <ul className="normal-text">
                        <li>{t('laboratory-1.page.section.achievements.topic-3.item-2')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-3.item-3')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-3.item-4')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-3.item-5')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-3.item-6')}</li>
                    </ul>
                </div>
            </div>

            <div className="normal-text additional-info">
                <p>{t('laboratory-1.page.section.achievements.topic-4.item-1')}</p>
                <p>{t('laboratory-1.page.section.achievements.topic-4.item-2')}</p>
                <p>{t('laboratory-1.page.section.achievements.topic-4.item-3')}</p>
                <p>{t('laboratory-1.page.section.achievements.topic-4.item-4')}</p>
            </div>
        </div>
    );
}

export default Laboratory1PagePartAchievements;
