import { useTranslation } from 'react-i18next';
import SideMenu from '../../components/side-menu';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import './development-page.scss';
import { useNavigate } from 'react-router-dom';

const DevelopmentPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const bases = [
        {
            image: require('../../assets/images/research-lab-1.png'),
            title: t('development.page.topic-2.base-1.title'),
            description: t('development.page.topic-2.base-1.description'),
            path: '/development/base-1'
        },
        {
            image: require('../../assets/images/research-lab-2.png'),
            title: t('development.page.topic-2.base-2.title'),
            description: t('development.page.topic-2.base-2.description'),
            path: '/development/base-2'
        }
    ];

    const handleNavigate = (path: string) => {
        navigate(path);
    }

    const basesItems = bases.map((item, index) => (
        <div className='item' key={index} onClick={() => {handleNavigate(item.path)}}>
            <div className='image-container'>
                <img src={item.image} />
            </div>
            <div className='title'>{item.title}</div>
            <div className='separator'></div>
            <span className='description'>{item.description}</span>
            <div className='arrow-container'>
                <ArrowIcon />
            </div>
        </div>
    ));
    return (
        <div className='development-page page'>
            <SideMenu />
            <div className='page-content'>
                <div className='topic-container'>
                <div className='title'>{t('development.page.topic-2.title')}</div>
                <div className='bases-container'>
                    {
                        basesItems.map((item) => (
                            <>
                            {item}
                            </>))
                    }
                </div>
                </div>
            </div>
        </div>
    )
}

export default DevelopmentPage;