import { useTranslation } from "react-i18next";

const Laboratory1PagePartProjects: React.FC = () => {

    const { t } = useTranslation();

    return (
        <>
            <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-1.item-1')} </p>

            <div className="section-content">
                <ul>
                    <li><p>{t('laboratory-1.page.section.projects.topic-1.item-2')}</p></li>
                </ul>
            </div>
            <div className="section-content">
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-2.item-1')} </p>
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-2.item-2')} </p>
            </div>
            <div className="section-content">
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-3.item-1')} </p>
                <ul>
                    <li><p>{t('laboratory-1.page.section.projects.topic-3.item-2')}</p></li>
                    <li><p>{t('laboratory-1.page.section.projects.topic-3.item-3')}</p></li>
                </ul>
            </div>
            <div className="section-content">
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-4.item-1')} </p>
                <ul>
                    <li><p>{t('laboratory-1.page.section.projects.topic-4.item-2')}</p></li>
                    <li><p>{t('laboratory-1.page.section.projects.topic-4.item-3')}</p></li>
                    <li><p>{t('laboratory-1.page.section.projects.topic-4.item-4')}</p></li>
                </ul>
            </div>
            <div className="section-content">
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-5.item-1')} </p>
            </div>
            <div className="section-content">
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-5.item-2')} </p>
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-5.item-3')} </p>
            </div>

            <div className="section-content">
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-6.item-1')} </p>
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-6.item-2')} </p>
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-6.item-3')} </p>
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-6.item-4')} </p>
            </div>

            <div className="section-content">
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-7.item-1')} </p>
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-7.item-2')} </p>
            </div>

            <div className="section-content">
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-8.item-1')} </p>
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-9.item-1')} </p>
            </div>

            <div className="section-content">
                <p className="normal-text"> {t('laboratory-1.page.section.projects.topic-10.item-1')} </p>
                <ul>
                    <li><p>{t('laboratory-1.page.section.projects.topic-10.item-2')}</p></li>
                    <li><p>{t('laboratory-1.page.section.projects.topic-10.item-3')}</p></li>
                </ul>
            </div>
        </>
    )
}

export default Laboratory1PagePartProjects;