import { ReactComponent as PdfIcon } from '../assets/icons/pdf.svg';
import { ReactComponent as DownloadIcon } from '../assets/icons/download.svg';
import './download-button.scss';

interface DownloadButtonProps {
    theme: 'white' | 'yellow' | 'green';
    label: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({theme, label}) => {
    return (
        <div className={`download-button  ${theme}`}>
            <PdfIcon />
            <span>{label}</span>
            <div className='download-container'>
                <DownloadIcon width={26} height={26}/>
            </div>
        </div>
    )
}

export default DownloadButton;